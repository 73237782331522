import React from 'react';
import { Nav } from 'reactstrap';

const NavHoc = ({ children, ...passThroughProps }) => (
  <Nav navbar {...passThroughProps}>
    {children}
  </Nav>
);

export default NavHoc;
