import React, { FunctionComponent, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Tooltip from 'reactstrap/lib/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type propTypes = {
  icon: IconDefinition,
  tooltipText: string
}

const SkillIcon: FunctionComponent<propTypes> = ({ icon, tooltipText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const tooltipId = tooltipText + "id"

  return (<div>
    <FontAwesomeIcon id={tooltipId} icon={icon} />
    <Tooltip placement="top" isOpen={tooltipOpen} target={tooltipId} toggle={toggleTooltip}>
      {tooltipText}
    </Tooltip>
  </div>)
}

export default SkillIcon
