import React, { useState } from 'react';

// css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/resume.scss';

// img
import ProfileImg from '../images/hang.jpg';
import favicon from '../images/favicon_io/favicon.ico';

// component
import SEO from '../components/seo';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink,
} from 'reactstrap';
import Scrollspy from 'react-scrollspy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faTrophy } from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faLinkedin,
  faPython,
  faJava,
  faJsSquare,
  faJenkins,
  faReact,
  faAngular,
  faAws,
} from '@fortawesome/free-brands-svg-icons';
import Experience from '../components/experience';
import Education from '../components/education';
import NavHoc from '../components/navHoc';
import SkillIcon from '../components/skillIcon';
import { Helmet } from 'react-helmet';

const IndexPage = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  const ABOUT = {
    firstName: 'Hang',
    lastName: 'Zhou',
    address: 'Bay Area California',
    email: 'job@zhouhang.net',
    bio:
      'I’m a full stack Software Engineer with a passion for developing innovative applications that expedite organizational success. I have thorough hands-on experience in React, Angular for frontend development. For the backend, I heavily utilize AWS services like Lambda, S3, dynamoDB, SQS and SNS etc.',
    linkedinUrl: 'https://www.linkedin.com/in/hangzh/',
    githubUrl: 'https://github.com/hangzho',
  };

  const NAVLIST = [
    { href: '#about', content: 'About' },
    { href: '#experience', content: 'Experience' },
    { href: '#education', content: 'Education' },
    { href: '#skills', content: 'Skills' },
    { href: '#awards', content: 'Awards' },
  ];

  const EXPERIENCELIST = [
    {
      company: 'Salesforce',
      title: 'Software Engineer',
      duration: 'Nov 2019 - Present',
      duties: [],
    },
    {
      company: 'Asurion',
      title: 'Software Engineer Full Stack',
      duration: 'Jul 2018 - Sep 2019',
      duties: [
        'Heavily used Amazon Web Services, such as AWS Lambda, API gateway, S3, RDS and DynamoDB etc. to build serverless web applications.',
        'Implemented the highly scalable asynchronous services using Lambda, SQS and Kinesis etc.',
        'Utilized the React, Redux and Bootstrap to develop the frontend UI in a user-friendly fashion.',
        'Refactored the legacy codebase to a better structured and manageable project. The refactoring includes creating microservices, integrating Kibana with logs, accelerating the deployment frequency by CI/CD tools, such as Jenkins and Terraform, and automatically running different types of tests using unittest, Jest and loki as part of the build process. ',
        'Worked closely with other technical teams, discussed the trade-offs of the integration between two systems and finalized the best solution to complete the task timely. ',
        'Extracted and processed the data from multiple data source using Python, SQL and Mixpanel to analyze the product performance to reach the KPIs. - Effectively collaborated with Product and Design team as part of Journey team to perform product discovery, user testing, prototyping and design/implementing final solutions. ',
        'Mentored junior software engineers on software development best practices. ',
        'Cooperated with offshore team smoothly in the early morning or late night to achieve objects.',
      ],
    },
    {
      company: 'Signet Accel',
      title: 'Full Stack Developer',
      duration: 'Dec 2015 - Jul 2018',
      duties: [
        'Developed and extended the back-end logic using Groovy on Grails framework according to documented software requirements. ',
        'Designed database based on the E-R diagram. Indicates the properties and relations. Normalized relations to eliminate data redundancy. ',
        'Optimized data visualization of the web application through server-side pagination and database refactoring. The data presentation speed is increased by 320%. ',
        'Implemented the front-end use cases with Angular.js, Redux and Webpack. ',
        'Introduced the testing tools including Spock, Jasmine, Karma and Protractor to run unit tests, integration tests for both front-end and back-end functionalities to improve system robustness. ',
        'Set up server environment including firewall, network ports and installed the information systems to the Linux server. ',
        'Utilized Jenkins, Gitlab CI and Docker to achieve software continuous delivery. ',
        'Cooperated with the business analyst and utilized UML to do functional, structural and behavioral modeling for the new information system design. ',
        'Provided clients support regarding technical questions and helped them to address issues. ',
        'Wrote documentations and release notes for the projects using the Confluence wiki.',
      ],
    },
    {
      company: 'Kenta',
      title: 'Software Engineer Intern',
      duration: 'Aug 2015 - Dec 2015',
      duties: [
        'Improved and debugged the single page web application’s functionalities with JavaScript, Angular.js and Node.js. ',
        'Created end-to-end automation test cases and unit test cases for the web application using Protractor, Jasmine.js, Selenium and Karma. ',
        'Automated the process of concatenating, minifying and cleaning project’s files using Grunt. ',
        'Validated and manipulated data from firebase web service.',
      ],
    },
    {
      company: 'University of Florida',
      title: 'Web Developer Intern',
      duration: 'Feb 2015 - Apr 2015',
      duties: [
        'Analyzed customer requirement to design elegant website wireframe, and optimized website layout by using JavaScript, HTML, CSS. ',
        'Deployed a Wordpress CMS website on Internet web server. ',
        'Utilized PHP and JavaScript to customize Wordpress map plugin and embedded it into the website. ',
        'Developed a webpage to manage map plugin in a visual way instead of in a coding way.',
      ],
    },
  ];

  const EDUCATIONLIST = [
    {
      school: 'University of Florida',
      major: 'Information Systems and Operations Management',
      degree: 'Master of Science',
      gpa: 'GPA: 3.73/4.00',
      duration: '2013 - 2015',
    },
    {
      school: 'Zhejiang Gongshang University',
      major: 'Information Management and Information System',
      degree: 'Bachelor of Management',
      gpa: 'GPA: 3.56/4.00',
      duration: '2011 - 2013',
    },
    {
      school: 'Zhejiang University City College',
      major: 'Finance and Banking',
      gpa: 'GPA: 3.85/4.00',
      duration: '2009 - 2011',
    },
  ];

  const SKILLLIST = [
    { tooltipText: 'Java', icon: faJava },
    { tooltipText: 'AWS', icon: faAws },
    { tooltipText: 'Javascript', icon: faJsSquare },
    { tooltipText: 'React', icon: faReact },
    { tooltipText: 'Angular', icon: faAngular },
    { tooltipText: 'Python', icon: faPython },
    { tooltipText: 'SQL', icon: faDatabase },
    { tooltipText: 'Jenkins', icon: faJenkins },
  ];
  return (
    <div>
      <Helmet>
        {/* https://favicon.io/favicon-generator/?t=H&ff=Itim&fs=110&fc=%23FFFFFF&b=rounded&bc=%2317a2b8 */}
        <link rel="icon" href={favicon} />
        {/* <!-- Custom fonts for this template --> */}
        <link
          href="https://fonts.googleapis.com/css?family=Saira+Extra+Condensed:500,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Muli:400,400i,800,800i"
          rel="stylesheet"
        />
      </Helmet>
      <SEO title="Hang Zhou" />
      <Navbar
        id="sideNav"
        className="fixed-top"
        dark
        expand="lg"
        color="primary"
      >
        <NavbarBrand href="#page-top">
          <span className="d-block d-lg-none">
            {ABOUT.firstName + ' ' + ABOUT.lastName}
          </span>
          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile rounded-circle mx-auto mb-2"
              src={ProfileImg}
              alt=""
            />
          </span>
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={!collapsed} navbar>
          <Scrollspy
            componentTag={NavHoc}
            items={['about', 'experience', 'education', 'skills', 'awards']}
            currentClassName="active"
          >
            {NAVLIST.map((item, idx) => {
              return (
                <NavItem key={idx}>
                  <NavLink onClick={toggleNavbar} href={item.href}>
                    {item.content}
                  </NavLink>
                </NavItem>
              );
            })}
          </Scrollspy>
        </Collapse>
      </Navbar>

      <div className="container-fluid p-0">
        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="about"
        >
          <div className="w-100">
            <h1 className="mb-0">
              {ABOUT.firstName}
              <span className="text-primary"> {ABOUT.lastName}</span>
            </h1>
            <div className="subheading mb-5">
              {ABOUT.address} ·
              <a href={'mailto:' + ABOUT.email}>{ABOUT.email}</a>
            </div>
            <p className="lead mb-5">{ABOUT.bio}</p>
            <div className="social-icons">
              <a
                target="_blank"
                href={ABOUT.linkedinUrl}
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                target="_blank"
                href={ABOUT.githubUrl}
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </div>
          </div>
        </section>

        <hr className="m-0" />

        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="experience"
        >
          <div className="w-100">
            <h2 className="mb-5">Experience</h2>
            {EXPERIENCELIST.map((props, idx) => (
              <Experience key={idx} {...props} />
            ))}
          </div>
        </section>

        <hr className="m-0" />

        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="education"
        >
          <div className="w-100">
            <h2 className="mb-5">Education</h2>
            {EDUCATIONLIST.map((props, idx) => (
              <Education key={idx} {...props} />
            ))}
          </div>
        </section>

        <hr className="m-0" />

        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="skills"
        >
          <div className="w-100">
            <h2 className="mb-5">Skills</h2>
            <div className="subheading mb-3">
              Programming Languages &amp; Tools
            </div>
            <ul className="list-inline dev-icons">
              {SKILLLIST.map((props, idx) => {
                return (
                  <li key={idx} className="list-inline-item">
                    <SkillIcon {...props} />
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <hr className="m-0" />

        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="awards"
        >
          <div className="w-100">
            <h2 className="mb-5">Awards &amp; Certifications</h2>
            <ul className="fa-ul mb-0 ml-2">
              <li>
                <FontAwesomeIcon
                  className="text-warning mr-1"
                  icon={faTrophy}
                />
                <a
                  href="https://www.certmetrics.com/amazon/public/badge.aspx?i=2&amp;t=c&amp;d=2019-05-17&amp;ci=AWS00832895"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>Certification Name</strong>: AWS Certified Developer -
                  Associate
                </a>
              </li>
              <li>
                <FontAwesomeIcon
                  className="text-warning mr-1"
                  icon={faTrophy}
                />
                <a
                  href="https://www.betagammasigma.org/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Beta Gamma Sigma
                </a>
              </li>
              <li>
                <FontAwesomeIcon
                  className="text-warning mr-1"
                  icon={faTrophy}
                />
                <a
                  href="https://www.alphaiotadelta.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Alpha Iota Delta
                </a>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default IndexPage;
