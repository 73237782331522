import React from 'react';
import PropTypes from 'prop-types';

const Education = ({ school, degree, major, duration, gpa }) => (
  <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
    <div className="resume-content">
      <h3 className="mb-0">{school}</h3>
      <div className="subheading mb-3 d-flex justify-content-between">
        {degree} <span className="text-primary resume-date-sm">{duration}</span>
      </div>
      <div>{major}</div>
      <p>{gpa}</p>
    </div>
    <div className="resume-date text-md-right">
      <span className="text-primary">{duration}</span>
    </div>
  </div>
);

Education.propTypes = {
  school: PropTypes.string,
  major: PropTypes.string,
  degree: PropTypes.string,
  duration: PropTypes.string,
  gpa: PropTypes.string,
};

Education.defaultProps = {
  school: '',
  major: '',
  degree: '',
  duration: '',
  gpa: '',
};

export default Education;
