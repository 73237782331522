import React from 'react';
import PropTypes from 'prop-types';

const Experience = ({ company, title, duration, duties }) => (
  <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
    <div className="resume-content">
      <h3 className="mb-0">{title}</h3>
      <div className="subheading mb-3 d-flex justify-content-between">
        {company}
        <span className="text-primary resume-date-sm">{duration}</span>
      </div>
      <ul className="pl-3">
        {duties.map((duty, idx) => (
          <li key={idx}>{duty}</li>
        ))}
      </ul>
    </div>
    <div className="resume-date text-md-right">
      <span className="text-primary">{duration}</span>
    </div>
  </div>
);

Experience.propTypes = {
  company: PropTypes.string,
  title: PropTypes.string,
  duration: PropTypes.string,
  duties: PropTypes.array,
};

Experience.defaultProps = {
  company: '',
  title: '',
  duration: '',
  duties: [],
};

export default Experience;
